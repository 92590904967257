@import "src/themes/daikiri/styles/index.scss";
.ai-module {
  position: relative;

  @include sm {
    max-width: 700px;
    display: flex;
    flex-direction: row-reverse;
    margin: 0 auto;
  }

  @include lg {
    max-width: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include sm {
      width: 50%;
    }

    @include lg {
      justify-content: center;
    }

    &__title {
      margin-bottom: space(1.5);
      &__text {
        position: relative;
        border-radius: 70px;
        margin: 0 auto;
        max-width: space(14);
        padding: 8px 16px;
        background-color: $ai-title-bg-color;

        h2 {
          line-height: 32px;
        }

        @include sm {
          max-width: space(16);
          padding: 8px 8px;
        }

        @include md {
          h2 {
            font-size: space(1.5);
          }
        }

        @include lg {
          max-width: space(30);
          padding: 16px;

          h2 {
            font-size: space(2);
          }
        }

        &__chip {
          position: absolute;
          top: -18px;
          right: -5px;

          @include sm {
            & :first-child {
              font-size: space(1.2);
            }
          }
        }
      }
    }
    &__subtitle {
      margin-bottom: space(1.5);
      padding: 0 space(1);

      @include sm {
        max-width: space(19);
        margin: 0 auto;
      }

      @include lg {
        max-width: space(28);
      }

      p {
        font-size: space(1.125);
        font-weight: 400;

        @include sm {
          margin-bottom: space(1.5);
        }
      }
    }
    &__cta {
      position: absolute;
      bottom: space(-5);

      @include sm {
        position: relative;
        bottom: 0;
      }
    }
  }
  &__image {
    padding: space(0.5);
    margin-bottom: space(3.5);
    display: flex;
    justify-content: center;

    @include sm {
      width: 50%;
      height: 50%;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    img {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      max-width: 80%;

      @include sm {
        height: 100%;
        max-width: 100%;
      }

      @include lg {
        max-width: 83%;
      }
    }
  }
}
